import React from "react"
import { Link } from "gatsby"
import Page from "~common/components/Page"

const Page404 = () => (
  <Page>
    <Page.Header>
      <Page.Header.Title>404</Page.Header.Title>
      <Page.Header.Description>
        Please click <Link to="/">here</Link> to return home or contact the
        administrator.
      </Page.Header.Description>
    </Page.Header>
  </Page>
)

export default Page404
